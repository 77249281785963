import React from 'react'
import { Container } from 'reactstrap'
import { HeaderItem, IconLink } from './bannerHelper'
import { FaInstagram, FaFacebookF, FaMapMarkerAlt, FaPhone } from 'react-icons/fa'
import { BiLogInCircle, BiPhoneCall } from 'react-icons/bi'

let Banner = ({
  title,
  phone,
  facebook_url = null,
  instagram_url = null,
  address
}) => {
  const phone_url = 'tel:' + phone.replace(/ /g, '')
  return (
    <div className="bg-light">
      <Container>
        <div className="d-flex py-2 align-items-center">
          <div className="d-flex mr-auto">
            <span className="d-none d-md-inline font-weight-bold header-caption">
              {title}
            </span>
            <HeaderItem
              text={phone}
              type="phone"
              Icon={BiPhoneCall}
              url={phone_url}
              klass="d-md-none display-none"
            />
          </div>
          <div className="d-flex">
            <HeaderItem
              text={phone}
              type="phone"
              Icon={BiPhoneCall}
              url={phone_url}
              klass="d-md-flex d-none mr-3 display-none"
            />
            <HeaderItem
              text="Личный кабинет"
              type="cabinet"
              Icon={BiLogInCircle}
              url="https://my.debttracker.ru/"
              klass="d-sm-flex d-none mr-3"
            />
            <HeaderItem
              text="Личный кабинет"
              type="cabinet"
              Icon={BiLogInCircle}
              url="https://my.debttracker.ru/"
              klass="d-sm-none"
            />
            {facebook_url ? (
              <IconLink
                href={facebook_url}
                Icon={FaFacebookF}
              />
            ) : (
              ''
            )}
            {instagram_url ? (
              <IconLink
                href={instagram_url}
                Icon={FaInstagram}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Banner
