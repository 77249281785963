import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import Link from './link';
import styled from "styled-components"
import footerItems from '../data/footer'

const FooterStyling = styled.footer`
  padding: 3rem 0;
  background: #f8f9fa;
  a, a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
  }
`

let SocialLink = ({Icon}) => (
  <Link to="/" className="mr-2">
    <Icon size={30}/>
  </Link>
)

let FooterLink = ({to, children}) => (
  <li>
    <Link to={to}>
      {children}
    </Link>
  </li>
)

/*

<SocialLink Icon={FaFacebookSquare}/>
<SocialLink Icon={FaInstagram}/>

<h5>ООО «Альтернатива»</h5>
<ul>ИНН: 7729697167 ОГРН: 1117746968844</ul>
<ul>Юридический адрес: 108841, Россия, г. Москва, г. Троицк, пл. Академическая, д. 4, этаж 18, офис А5Д</ul>

*/

let Footer = ({email}) => (
  <FooterStyling>
    <Container>
      <Row>
        <Col xs={12} md={3}>
          <h5>DEBTTRACKER</h5>
          <ul>
            {footerItems.map(dropdownItem => (
              <FooterLink to={dropdownItem.url}>{dropdownItem.name}</FooterLink>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5}>

        </Col>
        <Col xs={12} md={4}>
          <h5>Свяжитесь с нами</h5>
          <ul><FooterLink to={`mailto:${email}`}>{email}</FooterLink>
          </ul>
        </Col>
      </Row>
    </Container>
  </FooterStyling>
)


export default Footer
