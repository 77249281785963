import React from 'react'
import {Container, Row, Col} from 'reactstrap'

let SubFooter = ({title}) => (
  <div className="bg-light">
    <Container className="pb-3">
      <Row>
        <Col xs={12} md={3}>
          <span className="text-secondary small">Copyright © {new Date().getFullYear()}. DEBTTRACKER.RU</span>
        </Col>
        <Col xs={12} md={5}>

        </Col>
        <Col xs={12} md={4}>
          <a className="text-secondary small" href="/policy">Политика обработки персональных данных</a>
        </Col>
      </Row>
    </Container>
  </div>
)

export default SubFooter
